import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../components/Home";
import About from "../components/About";
import Login from "../components/Login";
import Profile from "../components/Profile";
import Authentication from "./Authentication";
import Authorization from "./Authorization";
import PERMISSIONS from "../permissions/Permissions";
import Extra from "../components/Extra";
import AdminLayout from "../layouts/admin";
import AuthLayout from "../layouts/auth";
import RTLLayout from "../layouts/rtl";
import { useAuth } from "provider/AuthProvider";
const RoutePath = () => {

    const {user} = useAuth()

    return (
        <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route element={<Authorization permissions={[PERMISSIONS.CAN_ACCESS_ADMIN, PERMISSIONS.CAN_ACCESS_USER_VIEW]} />}>
                <Route path="/admin/*" element={<Authentication><AdminLayout /></Authentication>} />
            </Route>
            <Route element={<Authorization permissions={[PERMISSIONS.CAN_VIEW_EXTRA]} />}>
                <Route path="extra" element={<Extra />} />
            </Route>
            <Route path="/rtl/*" element={<RTLLayout />} />
            {/* <Route path="/" element={<Navigate to="/admin/default" element={AdminLayout} />} /> */}
            <Route
                path="/"
                element={user.userEmail!=="" ? <Navigate to="/admin" /> : <AuthLayout />}
                replace
            />
        </Routes>
    );
};
export default RoutePath;

// import React from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
// import Home from "../components/Home";
// import About from "../components/About";
// import Login from "../components/Login";
// import Profile from "../components/Profile";
// import Authentication from "./Authentication";
// import Authorization from "./Authorization";
// import PERMISSIONS from "../permissions/Permissions";
// import Extra from "../components/Extra";
// import AdminLayout from "../layouts/admin";
// import AuthLayout from "../layouts/auth";
// import RTLLayout from "../layouts/rtl";
// import { useAuth } from "../provider/AuthProvider";

// const RoutePath = () => {
//   const { user } = useAuth();

//   return (
//     <Routes>
//       <Route path="/auth/*" element={<AuthLayout />} />
//       <Route
//        path="/admin/*" 
//         element={
//           <Authorization
//             permissions={[PERMISSIONS.CAN_ACCESS_ADMIN]}
//             element={<AdminLayout />}
//           />
//         }
//       />
//       <Route
//         element={
//           <Authorization
//             permissions={[PERMISSIONS.CAN_VIEW_EXTRA]}
//             element={<Extra />}
//           />
//         }
//       />
//       <Route path="/rtl/*" element={<RTLLayout />} />
//       <Route
//         path="/"
//         element={user.userEmail ? <Navigate to="/admin" /> : <Login />}
//       />
//     </Routes>
//   );
// };

// export default RoutePath;
