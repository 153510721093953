import axios from "axios";

const axiosWithGlobalLoader = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
});
axiosWithGlobalLoader.interceptors.request.use((config) => {
  return config;
});
axiosWithGlobalLoader.defaults.headers.common["Authorization"] =
  localStorage.getItem("AuthenticationToken") || sessionStorage.getItem("AuthenticationToken");
axiosWithGlobalLoader.defaults.headers.post["Content-Type"] =
  "application/json";
axiosWithGlobalLoader.defaults.timeout = 12000;
axiosWithGlobalLoader.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("AuthenticationToken");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("AuthenticationToken");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const handleRequest = async (
  url: string,
  method: string,
  headers: any,
  allowToast?: boolean,
  toast?: any,
  data?: any
  // customAuthorization?: boolean
) => {
  let response;
  try {
    if (headers?.Authorization ? false : true) {
      headers = {
        ...headers,
        Authorization: localStorage.getItem("AuthenticationToken") || sessionStorage.getItem("AuthenticationToken"),
      };
    }

    if (method === "get") {
      response = await axiosWithGlobalLoader({
        url,
        method: "GET",
        headers,
      });
    } else {
      response = await axiosWithGlobalLoader({
        url,
        method,
        data,
        headers,
      });
    }

    if (response.status === 200) {
      const responseData = response.data;
      // console.log(responseData);

      if (allowToast) {
        toast({
          title: "Success",
          description: "Request successful.",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      }

      return responseData;
    } else {
      
      if (allowToast) {
        toast({
          title: `Error`,
          description: response.data.message,
          status: "error",
          position: "top-right",
          isClosable: true,
          duration: 5000,
        });
      }
    }
  } catch (error: any) {
    console.log(error.response.data.message);
    toast({
      title: "Error",
      description: `There was an error processing your requestd.\n ${error.response.data.message}`,
      status: "error",
      position: "top-right",
      isClosable: true,
      duration: 5000,
    });

    throw error;
  }
};

export default axiosWithGlobalLoader;
