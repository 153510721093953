// // Chakra imports
// import { Portal, Box, useDisclosure } from "@chakra-ui/react";
// import Footer from "components/footer/FooterAdmin";
// // Layout components
// import Navbar from "components/navbar/NavbarAdmin";
// import Sidebar from "components/sidebar/Sidebar";
// import { SidebarContext } from "contexts/SidebarContext";
// import { useState } from "react";
// import { Redirect, Route, Switch } from "react-router-dom";
// import routes from "routes";
// import { useEffect } from "react";

// // Custom Chakra theme
// export default function Dashboard(props: { [x: string]: any }) {
//   const { ...rest } = props;
//   // states and functions
//   const [fixed] = useState(false);
//   const [toggleSidebar, setToggleSidebar] = useState(false);
//   // functions for changing the states from components

//   const getRoute = () => {
//     return window.location.pathname !== "/admin/full-screen-maps";
//   };
//   const getActiveRoute = (routes: RoutesType[]): string => {
//     let activeRoute = "Default Brand Text";
//     for (let i = 0; i < routes.length; i++) {
//       if (
//         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//       ) {
//         return routes[i].name;
//       }
//     }
//     return activeRoute;
//   };
//   const getActiveNavbar = (routes: RoutesType[]): boolean => {
//     let activeNavbar = false;
//     for (let i = 0; i < routes.length; i++) {
//       if (
//         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//       ) {
//         return routes[i].secondary;
//       }
//     }
//     return activeNavbar;
//   };
//   const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
//     let activeNavbar = false;
//     for (let i = 0; i < routes.length; i++) {
//       if (
//         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
//       ) {
//         return routes[i].name;
//       }
//     }
//     return activeNavbar;
//   };
//   const getRoutes = (routes: RoutesType[]): any => {
//     return routes.map((route: RoutesType, key: any) => {
//       if (route.layout === "/admin") {
//         return (
//           <Route
//             path={route.layout + route.path}
//             component={route.component}
//             key={key}
//           />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   document.documentElement.dir = "ltr";
//   const { onOpen } = useDisclosure();

//   if (!localStorage.getItem("login")) {
//     console.log("hello");
//     return <Redirect to="/auth" />;
//   }
//   return (
//     <Box>
//       <SidebarContext.Provider
//         value={{
//           toggleSidebar,
//           setToggleSidebar,
//         }}
//       >
//         <Sidebar routes={routes} display="none" {...rest} />
//         <Box
//           float="right"
//           minHeight="100vh"
//           height="100%"
//           overflow="auto"
//           position="relative"
//           maxHeight="100%"
//           w={{ base: "100%", xl: "calc( 100% - 290px )" }}
//           maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
//           transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
//           transitionDuration=".2s, .2s, .35s"
//           transitionProperty="top, bottom, width"
//           transitionTimingFunction="linear, linear, ease"
//         >
//           <Portal>
//             <Box>
//               <Navbar
//                 onOpen={onOpen}
//                 logoText={"Horizon UI Dashboard PRO"}
//                 brandText={getActiveRoute(routes)}
//                 secondary={getActiveNavbar(routes)}
//                 message={getActiveNavbarText(routes)}
//                 fixed={fixed}
//                 {...rest}
//               />
//             </Box>
//           </Portal>

//           {getRoute() ? (
//             <Box
//               mx="auto"
//               p={{ base: "20px", md: "30px" }}
//               pe="20px"
//               minH="100vh"
//               pt="50px"
//             >
//               <Switch>
//                 {getRoutes(routes)}
//                 <Redirect from="/" to="/admin/default" />
//               </Switch>
//             </Box>
//           ) : null}
//           <Box>
//             <Footer />
//           </Box>
//         </Box>
//       </SidebarContext.Provider>
//     </Box>
//   );
// }

import { useState, useEffect } from "react";
import { Route, Routes, Outlet, Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  useColorModeValue,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import routes from "routes";

export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  let modifiedRoutes = routes;

  // useEffect(() => {
  //   if (!localStorage.getItem("login")) {
  //     console.log("hello");
  //     navigate("/auth", { replace: true });
  //   }
  // }, []);

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const storedUser = JSON.parse(localStorage.getItem("user")) || JSON.parse(sessionStorage.getItem("user"));
  
  console.log("<<<<",storedUser)
  if (storedUser.userPermissions[0] === "Access_user_view") {
    modifiedRoutes = modifiedRoutes.filter(
      (route: any) => route.name !== "Users Management"
    );
  }

  const getActiveRoute = (modifiedRoutes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < modifiedRoutes.length; i++) {
      if (
        window.location.href.indexOf(modifiedRoutes[i].layout + modifiedRoutes[i].path) !== -1
      ) {
        return modifiedRoutes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (modifiedRoutes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < modifiedRoutes.length; i++) {
      if (
        window.location.href.indexOf(modifiedRoutes[i].layout + modifiedRoutes[i].path) !== -1
      ) {
        return modifiedRoutes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (modifiedRoutes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < modifiedRoutes.length; i++) {
      if (
        window.location.href.indexOf(modifiedRoutes[i].layout + modifiedRoutes[i].path) !== -1
      ) {
        return modifiedRoutes[i].name;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (modifiedRoutes: RoutesType[]): any => {
    return modifiedRoutes.map((route: RoutesType, key: any) => {
      if (route.layout === "/admin") {
        return (
          <Route path={route.path} element={<route.component />} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={modifiedRoutes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Horizon UI Dashboard PRO"}
                brandText={getActiveRoute(modifiedRoutes)}
                secondary={getActiveNavbar(modifiedRoutes)}
                message={getActiveNavbarText(modifiedRoutes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Routes>
                {getRoutes(modifiedRoutes)}
                {storedUser.userPermissions[0] === "Access_admin" ? 
                  <Route path="/" element={<Navigate to="/admin/default" />} /> 
                  :
                  <Route path="/" element={<Navigate to="/admin/content-management" />} />
                }
              </Routes>
            </Box>
          ) : null}
          <Box>{/* <Footer /> */}</Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
