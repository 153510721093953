import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Icon,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { MdPeople } from "react-icons/md";
import { handleRequest } from "services/axiosConfig";

const UserToggleDialog = (props: { onUserEnableClose: any; fetchUserData: () => void; user: any }) => {
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();

  const handleUserToggle = async () => {
    try {
      setGlobalLoading(true);
      const responseData = await handleRequest(
        `/user-service/toggle-user-allowance/${props.user.userId}`,
        "get",
        {
          "Content-Type": "application/json",
        },
        false,
        toast
      );
    } finally {
      setGlobalLoading(false);
      props.onUserEnableClose();
      props.fetchUserData();
    }
  };

  return (
    <ModalContent>
      <ModalHeader display="flex" alignItems="center">
        <Icon as={MdPeople} boxSize={6} mr={2} />
        Toggle User Status
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody pb={6}>
        <Flex>
          {props.user.isUserAllowed ? (
            <Text>Do you want to disable this User?</Text>
          ) : (
            <Text>Do you want to enable this User?</Text>
          )}
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="brand"
          onClick={props.onUserEnableClose}
          variant={"outline"}
          mr={3}
        >
          Cancel
        </Button>
        {props.user.isUserAllowed ? (
          <Button colorScheme="brand" onClick={handleUserToggle}>
            Disable User
          </Button>
        ) : (
          <Button colorScheme="brand" onClick={handleUserToggle}>
            Enable User
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  );
};

export default UserToggleDialog;
