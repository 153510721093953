import {
  Box,
  Button,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";

import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";

// Custom components

import user_adherence_page_illustration from "assets/img/user-adherence/user_adherence_page_illustration.svg";
import Card from "components/card/Card";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import TableHeader from "./components/tableHeader";

export default function UserAdherence() {
  const [date, setDate] = React.useState(new Date());
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#cdd9e8", "white");
  const rowSeperatorColor = useColorModeValue(
    "rgba(6, 64, 139, 0.2)",
    "rgba(100,100,100,0.2)"
  );
  const { setGlobalLoading } = useGlobalLoading();
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  // const [selectedDates, setSelectedDates] = React.useState<Date[]>([
  //   new Date(),
  //   new Date(),
  // ]);
  const [fromDate, setFromDate] = React.useState<Date>(new Date());
  const [toDate, setToDate] = React.useState<Date>(new Date());

  return (
    <Box h={"90vh"} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <TableHeader headingTitle="Users Adherence" />
      <Card
        flexDirection="column"
        w="100%"
        h="100%"
        px="0px"
        py="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        borderRadius={"10px"}
        borderWidth="1px"
        borderColor={rowSeperatorColor}
      >
        <Box
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          px="0px"
          py="0px"
          display={"flex"}
          verticalAlign={"middle"}
          h="100%"
          flexDir={"column"}
        >
          <img src={user_adherence_page_illustration} alt="calendar" />
          <HStack margin={"20px 0"}>
            <VStack alignItems="center">
              <Text>From</Text>
              <SingleDatepicker
                name="date-input"
                usePortal={true}
                propsConfigs={{
                  popoverCompProps: {
                    popoverContentProps: {
                      placeContent: "left",
                      zIndex: 300,
                    },
                  },
                }}
                date={fromDate}
                onDateChange={setFromDate }
              />
            </VStack>
            <VStack w="250px" alignItems="center">
              <Text>To</Text>
              <SingleDatepicker
                usePortal={true}
                propsConfigs={{
                  popoverCompProps: {
                    popoverContentProps: {
                      placeContent: "left",
                      zIndex: 300,
                    },
                  },
                }}
                name="date-input"
                date={toDate}
                minDate={fromDate}
                onDateChange={setToDate}
              />
              {/* <RangeDatepicker
                usePortal={true}
                propsConfigs={{
                  popoverCompProps: {
                    popoverContentProps: {
                      placeContent: "top",
                      zIndex: 300,
                    },
                  },
                }}
                selectedDates={selectedDates}
                onDateChange={setSelectedDates}
              /> */}
            </VStack>
          </HStack>

          <Button
            type="submit"
            colorScheme="brand"
            onClick={async () => {
              console.log(
                process.env.REACT_APP_BASE_URL +
                  "/" +
                  process.env.REACT_APP_API_VERSION +
                  "/user-service/export-dose-analytics?fromDate=" +
                  fromDate.toISOString() +
                  "&toDate=" +
                  toDate.toISOString()
              );
              // window.open(
              //   process.env.REACT_APP_BASE_URL +
              //     "/" +
              //     process.env.REACT_APP_API_VERSION +
              //     "/user-service/export-dose-analytics"
              // );
              window.open(
                process.env.REACT_APP_BASE_URL +
                  "/" +
                  process.env.REACT_APP_API_VERSION +
                  "/user-service/export-dose-analytics?fromDate=" +
                  fromDate.toISOString() +
                  "&toDate=" +
                  toDate.toISOString()
              );
              // try {
              //   setGlobalLoading(true);
              //   const responseData =
              //     await handleGetRequestWithToastNotification(
              //       "/user-service/export-dose-analytics",
              //       {
              //         // "Content-Type": "application/json",
              //       },
              //       toast
              //     );
              // } finally {
              //   setGlobalLoading(false);
              // }
            }}
          >
            Download Data
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
