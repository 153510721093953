import { useLocation } from 'react-router-dom';
import { useAuth } from 'provider/AuthProvider';
import React, { useState, useEffect } from 'react';
import { useToast } from "@chakra-ui/react";

const TIMEOUT = 15 * 60 * 1000;

const LogoutTimer = () => {
  const [nextLogoutTime, setNextLogoutTime] = useState(null);
  const [toastShown, setToastShown] = useState(false); // State to track whether toast is shown

  const location = useLocation();
  const { logout } = useAuth();
  const toast = useToast();

  const resetTimer = () => {
    setNextLogoutTime(Date.now() + TIMEOUT);
  };

  useEffect(() => {
    const isAuthRoute = location.pathname.startsWith('/auth');

    if (!isAuthRoute) {
      resetTimer(); // Start timer when component mounts
    }

    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    const handleActivity = () => {
      resetTimer();
    };

    for (let event of events) {
      document.addEventListener(event, handleActivity);
    }

    const checkLogout = () => {
      if (nextLogoutTime && Date.now() > nextLogoutTime && !toastShown) {
        // Show toast only if not already shown
        toast({
          title: `No activity detected. You will be logged out soon.`,
          description: '',
          status: "error",
          position: "top-right",
          isClosable: true,
          duration: 5000,
        });
        setTimeout(() => {
          console.log("<<<<<<<<<<<<")
          logout();
          window.location.reload();
        }, 3000); // Logout after 3 seconds
        setToastShown(true); // Set toastShown to true to prevent further showing
        
      }
    };

    const interval = setInterval(checkLogout, 1000); // Check every second

    return () => {
      for (let event of events) {
        document.removeEventListener(event, handleActivity);
      }
      clearInterval(interval); // Clear interval on unmount
    };
  }, [location.pathname, logout, nextLogoutTime, toastShown, toast]);

  return null; // No need to render anything
};

export default LogoutTimer;
