import React from "react";
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  FormErrorMessage,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { MdPersonAddAlt1 } from "react-icons/md";
import { Field, Formik } from "formik";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { handleRequest } from "services/axiosConfig";

const EditUserDialog = (props: { onClose: any; fetchUserData: () => void; user: any; }) => {
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();

  return (
    <Formik
      initialValues={{
        firstNameOfUser: "",
        lastNameOfUser: "",
        userIdOfUser: "",
      }}
      onSubmit={async (values) => {
        values.userIdOfUser = props.user.userId;
        
        try {
          setGlobalLoading(true);
          const responseData = await handleRequest(
            `/user-service/edit-user`,
            "post",
            {
              "Content-Type": "application/json",
            },
            true,
            toast({
              title: `User updated successfully!`,
              status: "success",
              duration: 3000,
              position: "top-right",
              isClosable: true,
            }),
            values,
          );
        } finally {
          setGlobalLoading(false);
          props.onClose();
          props.fetchUserData();
        }
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <ModalContent>
          <ModalHeader
            display="flex"
            alignItems="center"
            // color={headerContentColor}
          >
            <Icon as={MdPersonAddAlt1} boxSize={6} mr={2} />
            Edit User
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                  <FormControl
                    isInvalid={
                      !!errors.firstNameOfUser &&
                      touched.firstNameOfUser
                    }
                  >
                    <FormLabel>First Name</FormLabel>
                    <Field
                      as={Input}
                      id="firstNameOfUser"
                      name="firstNameOfUser"
                      validate={(value: any) => {
                        let error;

                        if (value.length < 1) {
                          error =
                            "First name must contain at least 1 characters";
                        }

                        return error;
                      }}
                    />
                    <FormErrorMessage>
                      {errors.firstNameOfUser}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Field
                      as={Input}
                      id="lastNameOfUser"
                      name="lastNameOfUser"
                      type="text"
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="brand"
                onClick={props.onClose}
                variant={"outline"}
                mr={3}
              >
                Discard
              </Button>
              <Button type="submit" colorScheme="brand">
                Save Details
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      )}
    </Formik>
  );
};

export default EditUserDialog;
