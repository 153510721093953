import React from "react";
import { useAuth } from "../provider/AuthProvider";
import { Navigate, useLocation } from "react-router-dom";
const Authentication = ({ children }) => {
    const { user } = useAuth();
    const location = useLocation();
    if (user.userEmail == "") {
        return <Navigate to="/" state={{ path: location.pathname }} replace/>;
    }
    return children;
};
export default Authentication;