import React from "react";
import { Navigate, NavLink, Route, Routes } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  Image
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import illustration from "assets/img/auth/auth.png";
import subLogoPNG from "assets/img/logo/alexion.svg";
import logoPNG from "assets/img/logo/Voydeya.svg";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { useFormik } from "formik";
import { useAuth } from "provider/AuthProvider";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { handleRequest } from "services/axiosConfig";

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("link.100", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { setGlobalLoading } = useGlobalLoading();

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { login } = useAuth();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validate: values => {
      const errors: any = {};

      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        setGlobalLoading(true);
        const responseData = await handleRequest(
          "/user-authentication/login",
          "post",
          {
            "Content-Type": "application/json",
          },
          false,
          toast,
          values,
        );
        login(responseData, values.rememberMe);
      } finally {
        setGlobalLoading(false);
      }
    },
  });

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
      logo={logoPNG}
      subLogo={subLogoPNG}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        h="100vh"
        alignItems="start"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        mx="auto"
        flexDirection="column"
      >
        <Image
          w="134px"
          h="36px"
          src={subLogoPNG}
          alt="subLogoPNG"
          position="absolute"
          top="20px"
          right="40px" 
        />
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Please enter below details to Sign In
          </Text>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Enter email or user name"
                mb="6px"
                fontWeight="500"
                size="lg"
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                // onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              {formik.touched.email && formik.errors.email ? (
                <Flex color={'red'} fontSize="sm" pl="5px" pb="20px">{formik.errors.email}</Flex>
              ) : null}

              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                id="password"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Password"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    // id="remember-login"
                    iconColor={useColorModeValue("#000", "#fff")}
                    colorScheme="#fff"
                    me="10px"
                    _focus={{ boxShadow: "none", border: "none" }}
                    id="rememberMe"
                    name="rememberMe"
                    onChange={formik.handleChange}
                  />
                  {/* <Field
                    as={Checkbox}
                    id="rememberMe"
                    name="rememberMe"
                    colorScheme="purple"
                  > */}
                  {/* </Field> */}
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Remember Password
                  </FormLabel>
                </FormControl>
                <NavLink to="/auth/forgot-password">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="124px"
                    fontWeight="500"
                  >
                    Forgot password?
                  </Text>
                </NavLink>
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                rightIcon={<ArrowForwardIcon />}
                type="submit"
              >
                Sign In
              </Button>
            </FormControl>
          </Flex>
        </form>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
