import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditablePreview,
  Flex,
  IconButton,
  Input,
  Select,
  Text,
  useColorModeValue,
  useEditableControls,
  Divider,
  FormControl,
  useToast,
  EditableInput,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import TableHeader from "./components/tableHeader";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { handleRequest } from "services/axiosConfig";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { ChangeEvent } from "react";

export default function ContentManagement() {
  const headerColor = useColorModeValue("#3F4444", "white");
  const subHeaderColor = useColorModeValue("#202121", "white");
  const buttonBgColor = useColorModeValue("white", "brand.450");
  const buttonColor = useColorModeValue("brand.450", "white");
  const buttonBgColorSecondary = useColorModeValue("#06408B", "white");
  const rowSeperatorColor = useColorModeValue(
    "rgba(6, 64, 139, 0.2)",
    "rgba(100,100,100,0.2)"
  );
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();
  const errors: any = {};

  const formik = useFormik({
    initialValues: {
      monthNumber: 0,
      textContent: "",
    },
    validate: (values) => {
      if (!values.monthNumber) {
        errors.monthNumber = "Month number is required";
      } else if (values.monthNumber <= 0) {
        errors.monthNumber = "Month number must be greater than 0";
      } else if (values.monthNumber > 12) {
        errors.monthNumber = "Month number must be less than 12";
      }

      if (!values.textContent) {
        errors.textContent = "Text content is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setGlobalLoading(true);
        const responseData = await handleRequest(
          "/user-service/post-month-content",
          "post",
          {
            "Content-Type": "application/json",
          },
          true,
          toast({
            title: `Content added successfully!`,
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          }),
          values
        );
      } finally {
        setGlobalLoading(false);
      }
    },
  });

  const parseMonth = (value: any) => {
    formik.setFieldValue("monthNumber", parseInt(value.target.value));
  };
  const months = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const options = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: months[index],
  }));

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Flex justifyContent="right" marginTop="8px">
        <ButtonGroup justifyContent="right" size="sm" marginTop="-44px">
          <IconButton
            aria-label="check"
            bg="inherit"
            borderRadius="inherit"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={
              <CheckIcon w="12px" h="12px" color={buttonBgColorSecondary} />
            }
            {...getSubmitButtonProps()}
          />
          <IconButton
            aria-label="close"
            bg="inherit"
            borderRadius="inherit"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={
              <CloseIcon w="12px" h="12px" color={buttonBgColorSecondary} />
            }
            {...getCancelButtonProps()}
          />
        </ButtonGroup>
      </Flex>
    ) : (
      <Flex justifyContent="right" marginTop="-44px">
        <IconButton
          aria-label="close"
          bg="inherit"
          borderRadius="inherit"
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          icon={<EditIcon w="15px" h="15px" color={buttonBgColorSecondary} />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box h={"90vh"} pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <FormControl>
          <TableHeader headingTitle="Content Management" />
          <Card
            flexDirection="column"
            w="100%"
            h="100%"
            px="0px"
            py="0px"
            pb="20px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            borderRadius={"10px"}
            borderWidth="1px"
            borderColor={rowSeperatorColor}
          >
            <Box
              alignContent={"baseline"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              px="0px"
              py="0px"
              display={"flex"}
              verticalAlign={"middle"}
              h="100%"
              flexDir={"column"}
            >
              <Flex
                display={"block"}
                alignSelf={"baseline"}
                px="25px"
                pt="25px"
                pb="30px"
                w="300px"
              >
                <Text
                  mb="6px"
                  ms="4px"
                  color={headerColor}
                  fontWeight="500"
                  fontSize="md"
                  h="30px"
                >
                  Select the Month
                </Text>
                <Select
                  placeholder="Select Month"
                  id="monthNumber"
                  name="monthNumber"
                  onChange={(value) => parseMonth(value)}
                  value={formik.values.monthNumber}
                >
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                {formik.touched.monthNumber && formik.errors.monthNumber ? (
                  <Flex fontSize="sm" color="red" pl="6px" pt="6px">
                    {formik.errors.monthNumber}
                  </Flex>
                ) : null}
              </Flex>

              <Divider w="98%" borderColor={"#CAC9C9"} />

              <Flex
                display="flex"
                w="100%"
                px="25px"
                mt="30px"
                justifyContent="space-between"
              >
                <Text
                  mb="6px"
                  ms="4px"
                  color={subHeaderColor}
                  fontWeight="500"
                  fontSize="md"
                >
                  Content Body
                </Text>
              </Flex>

              <Flex display="flex" w="100%">
                <Editable
                  textAlign="left"
                  defaultValue=""
                  placeholder="Enter content here..."
                  fontSize="sm"
                  isPreviewFocusable={false}
                  mx="26px"
                  border="1px solid #e2e8f0"
                  borderColor={rowSeperatorColor}
                  borderRadius={"10px"}
                  display="flex"
                  flexDirection="column"
                  w="95%"
                  h="125px"
                >
                  <EditableControls />
                  <EditablePreview w="1095px" pl="15px" pt="15px" />
                  <Input
                    as={EditableInput}
                    mt="-6px"
                    h="125px"
                    id="textContent"
                    name="textContent"
                    onChange={formik.handleChange}
                    value={formik.values.textContent}
                  />
                </Editable>
              </Flex>
              {formik.touched.textContent && formik.errors.textContent ? (
                <Flex
                  display="flex"
                  w="100%"
                  pl="32px"
                  fontSize="sm"
                  color="red"
                >
                  {formik.errors.textContent}
                </Flex>
              ) : null}

              <Flex>
                <Button
                  color={buttonColor}
                  borderColor={buttonColor}
                  borderWidth="0.5px"
                  bg={buttonBgColor}
                  borderRadius="10px"
                  padding={"16px 16px"}
                  height="46px"
                  width="200px"
                  mt="40px"
                  mr="10px"
                >
                  Discard
                </Button>
                <Button
                  color={buttonBgColor}
                  borderColor={buttonBgColor}
                  borderWidth="0.5px"
                  bg={buttonBgColorSecondary}
                  borderRadius="10px"
                  padding={"16px 16px"}
                  height="46px"
                  width="200px"
                  mt="40px"
                  ml="10px"
                  type="submit"
                >
                  Post
                </Button>
              </Flex>
            </Box>
          </Card>
        </FormControl>
      </Box>
    </form>
  );
}
