import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import { CalendarIcon, EmailIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { MdImportExport, MdInsertDriveFile, MdMoreVert, MdPerson } from "react-icons/md";
import { handleRequest } from "services/axiosConfig";
import TableHeader from "./components/tableHeader";
import EditUserDialog from "./components/EditDialog";
import { useState } from "react";
import UserToggleDialog from "./components/UserToggleDialog";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const columnHelper = createColumnHelper<any>();

export default function UserManagement() {

  const [data, setData] = React.useState([]);
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#cdd9e8", "white");
  const iconColorInTable = useColorModeValue("secondaryGray.900", "white");
  const rowSeperatorColor = useColorModeValue(
    "rgba(6, 64, 139, 0.2)",
    "rgba(100,100,100,0.2)"
  );
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [pageCount, setPageCount] = React.useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [startIndex, setStartIndex] = React.useState(1);
  const [endIndex, setEndIndex] = React.useState(10);
  
  const fetchUserData = async () => {
    try {
      setGlobalLoading(true);
      const responseData = await handleRequest(
        `/user-service/get-users-with-pagination?page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
        "get",
        {
          "Content-Type": "application/json",
        },
        false,
        toast
      );
      // console.log(responseData);
      setPageCount(Math.round(responseData.pagination.totalCount / responseData.pagination.pageSize));
      setData(responseData.users);
      setTotalCount(responseData.pagination.totalCount);

      setStartIndex(table.getState().pagination.pageIndex * table.getState().pagination.pageSize);
      let endIndex = (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize - 1;
      setEndIndex(Math.min(endIndex, totalCount - 1));
    } finally {
      console.log("there is an error");
      setGlobalLoading(false);
    }
  };

  React.useEffect(() => {
    fetchUserData();
  }, [pagination.pageIndex, pagination.pageSize, startIndex, endIndex]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isUserEnableOpen, setIsUserEnableOpen] = React.useState(false);
  const [user, setUser] = React.useState([]);
  const onClose = () => setIsOpen(false);
  const onUserEnableClose = () => setIsUserEnableOpen(false);
  const handleOpenEditUserDialog = (info: any) => {
    setUser(info.row.original);
    setIsOpen(true);
  };
  const handleUserEnableDialog = (info: any) => {
    setUser(info.row.original);
    setIsUserEnableOpen(true);
  };
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = [
    columnHelper.accessor("userId", {
      id: "userId",
      header: () => (
        <>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.0"
            textTransform="capitalize"
          >
            User ID
          </Text>
        </>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="500">
            <Icon as={MdInsertDriveFile} color={iconColor} /> {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.0"
          textTransform="capitalize"
        >
          Registered On
        </Text>
      ),
      cell: (info) => {
        const rawDate = info.getValue(); // Assuming info.getValue() returns the date string

        // Convert the date string to a JavaScript Date object
        const dateObject = new Date(rawDate);

        // Format the date as "hh:mm dd/mm/yy"
        const formattedDate = `${dateObject.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })} ${dateObject.toLocaleDateString()}`;

        return (
          <Text color={textColor} fontSize="sm" fontWeight="500">
            <CalendarIcon color={iconColor} /> {formattedDate}
          </Text>
        );
      },
    }),
    columnHelper.accessor("firstName", {
      id: "firstName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.0"
          textTransform="capitalize"
        >
          First Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="500">
          <Icon as={MdPerson} color={iconColor} /> {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("lastName", {
      id: "lastName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.0"
          textTransform="capitalize"
        >
          Last Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="500">
          <Icon as={MdPerson} color={iconColor} /> {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.0"
          textTransform="capitalize"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="500">
          <EmailIcon color={iconColor} />
          &nbsp; {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.0"
          textTransform="capitalize"
        >
          Action
        </Text>
      ),
      cell: (info) => (
        <Menu>
          <MenuButton>
            <Icon as={MdMoreVert} color={iconColorInTable} />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text
                  fontSize="sm"
                  onClick={() => handleOpenEditUserDialog(info)}
                >
                  Edit
                </Text>
              </MenuItem>
              {info.row.original.isUserAllowed ? (
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  px="14px"
                >
                  <Text 
                    fontSize="sm"
                    onClick={() => handleUserEnableDialog(info)}
                  >
                   Disable
                  </Text>
                </MenuItem>
              ) : (
                <MenuItem
                  _hover={{ bg: "none" }}
                  _focus={{ bg: "none" }}
                  borderRadius="8px"
                  px="14px"
                >
                  <Text 
                    fontSize="sm"
                    onClick={() => handleUserEnableDialog(info)}
                  >
                    Enable
                  </Text>
                </MenuItem>
              )}
            </Flex>
          </MenuList>
        </Menu>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: pageCount
  });

  const handlePageSizeChange = (e: { target: { value: any; }; }) => {
    table.setPageSize(Number(e.target.value));
    table.setPageIndex(0);
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <TableHeader
        headingTitle="Users Management"
        fetchUserData={fetchUserData}
      />
      <Card
        flexDirection="column"
        px="0px"
        py="0px"
        borderRadius={"10px"}
        borderWidth="1px"
        borderColor={rowSeperatorColor}
        overflowX={"auto"}
      >
        <Box py="0px">
          <Table variant="simple" color="gray.500">
            <Thead backgroundColor={"brand.450"}>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                        paddingRight="10px"
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color={textColor}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <Icon as={MdImportExport} width="22px" height="22px" color="white" />,
                            desc: <Icon as={MdImportExport} width="22px" height="22px" color="white" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows // .slice(0, 11)
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor={rowSeperatorColor}
                            whiteSpace="nowrap"
                            paddingRight="12px"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          <Flex
            display="flex"
            paddingBlock="10px"
            minW="900px"
            maxW="1200px"
            justifyContent="space-between"
          >
            <Flex>
              <Select
                pl="10px"
                w="75px"
                value={table.getState().pagination.pageSize}
                onChange={handlePageSizeChange}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>

              <Flex display="flex" alignItems="center" pl="20px">
                <Flex>Showing</Flex>
                <Flex pl="5px">
                  {startIndex + 1} - {endIndex + 1} of{" "}
                  {totalCount}
                </Flex>
              </Flex>
            </Flex>

            <Flex>
              <Button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                className="border rounded p-1"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <EditUserDialog
          onClose={onClose}
          fetchUserData={fetchUserData}
          user={user}
        />
      </Modal>
      <Modal isOpen={isUserEnableOpen} onClose={onUserEnableClose} size="2xl">
        <ModalOverlay />
        <UserToggleDialog
          onUserEnableClose={onUserEnableClose}
          fetchUserData={fetchUserData}
          user={user}
        />
      </Modal>
    </Box>
  );
}
