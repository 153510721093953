import PERMISSIONS from "permissions/Permissions";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosWithGlobalLoader from "services/axiosConfig";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/";
  const [user, setUser] = useState({
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userPermissions: []
  });


  const login = (response, rememberMe) => {
    // console.log(response);
    const { user, token } = response.data;

    let userObject;

    if (user.email === "superadmin@gmail.com") {
        userObject = {
            userEmail: user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userPermissions: [PERMISSIONS.CAN_ACCESS_ADMIN, PERMISSIONS.CAN_VIEW_EXTRA],
        };
    } else {
        userObject = {
            userEmail: user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userPermissions: [PERMISSIONS.CAN_ACCESS_USER_VIEW],
        };
    }

    setUser(userObject)

    if (rememberMe) {
      localStorage.setItem("user", JSON.stringify(userObject));
      localStorage.setItem("AuthenticationToken", token);
    } else {
      sessionStorage.setItem("user", JSON.stringify(userObject));
      sessionStorage.setItem("AuthenticationToken", token);
    }
    
    navigate(redirectPath, { replace: true });
  };


  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("AuthenticationToken");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("AuthenticationToken");
    setUser({ userEmail: "", userFirstName: "", userLastName: "", userPermissions: []  });
    navigate(redirectPath, { replace: true });
  };

  useEffect(() => {
    const storedUserToken = localStorage.getItem("AuthenticationToken") || sessionStorage.getItem("AuthenticationToken");
    if(storedUserToken){
        axiosWithGlobalLoader.defaults.headers.common["Authorization"] = localStorage.getItem("AuthenticationToken") || sessionStorage.getItem("AuthenticationToken");
    }
    
    const storedUserInfo = localStorage.getItem("user") || sessionStorage.getItem("user");
    // console.log('this is storeUser Info', JSON.parse(storedUserInfo))

    if (storedUserInfo) {
      //  console.log('this is storeUser Info', JSON.parse(storedUserInfo))
      const parsedUserInfo = JSON.parse(storedUserInfo);
      setUser({
          userEmail: parsedUserInfo.userEmail,
          userFirstName: parsedUserInfo.userFirstName,
          userLastName: parsedUserInfo.userLastName,
          userPermissions: parsedUserInfo.userPermissions,
      });
    }
  }, []);


  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
