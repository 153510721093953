// GlobalLoadingContext.js
import { Box, Spinner, Flex } from "@chakra-ui/react";
import React, { createContext, useState, useContext } from "react";

const GlobalLoadingContext = createContext();

export const GlobalLoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const setGlobalLoading = (value) => {
    setIsLoading(value);
  };

  return (
    <GlobalLoadingContext.Provider value={{ isLoading, setGlobalLoading }}>
      {children}
      {isLoading && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          width="100%"
          style={{backdropFilter: "blur(5px)"}}
          height="100%"
          alignItems="center"
          justifyContent="center"
          zIndex="9999"
        >
          <Spinner color="brand.450" emptyColor='gray.200' thickness='4px' size="xl" />
        </Flex>
      )}
    </GlobalLoadingContext.Provider>
  );
};

export const useGlobalLoading = () => {
  const context = useContext(GlobalLoadingContext);
  if (!context) {
    throw new Error(
      "useGlobalLoading must be used within a GlobalLoadingProvider"
    );
  }
  return context;
};
