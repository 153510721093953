import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { Field, Formik } from "formik";
import { useRef } from "react";
import { MdPersonAddAlt1 } from "react-icons/md";

export default function TableHeader(props: { headingTitle: string }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const headerContentColor = useColorModeValue("brand.450", "white");
  const buttonBgColor = useColorModeValue("white", "brand.450");
  const buttonColor = useColorModeValue("brand.450", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();

  return (
    <Flex
      mb="8px"
      justifyContent="space-between"
      align="center"
      w="100%"
      px="0px"
      py="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Text
        color={textColor}
        fontSize="20px"
        mb="4px"
        fontWeight="600"
        lineHeight="100%"
      >
        {props.headingTitle}
      </Text>
      {/* <Button
        color={buttonColor}
        borderColor={buttonColor}
        borderWidth="0.5px"
        bg={buttonBgColor}
        borderRadius="10px"
        onClick={onOpen}
        padding={"6px 16px"}
        height="36px"
        rightIcon={<AddIcon />}
      >
        Add User
      </Button>  */}
    </Flex>
  );
}
