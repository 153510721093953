import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  Text,
  useColorModeValue,
  useToast,
  Image,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import illustration from "assets/img/auth/auth.png";
import subLogoPNG from "assets/img/logo/alexion.svg";
import logoPNG from "assets/img/logo/Voydeya.svg";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { handleRequest } from "services/axiosConfig";

function SetNewPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("link.100", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();
  const navigate = useNavigate();

  const { token } = useParams(); // Get the token from the URL


  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: values => {
      const errors: any = {};

      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      
      try {
        setGlobalLoading(true);
        const responseData = await handleRequest(
          "/user-authentication/send-forget-password-email",
          "post",
          {
            "Content-Type": "application/json",
          },
          true,
          toast({
            title: `Password reset link is sent to your registered email.`,
            status: "success",
            duration: 6000,
            position: "top-right",
            isClosable: true,
          }),
          values,
        );
        navigate("/auth");
      } finally {
        setGlobalLoading(false);
      }
    },
  });

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
      logo={logoPNG}
      subLogo={subLogoPNG}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        h="100vh"
        alignItems="start"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        mx="auto"
        flexDirection="column"
      >
        <Image
          w="134px"
          h="36px"
          src={subLogoPNG}
          alt="subLogoPNG"
          position="absolute"
          top="20px"
          right="40px" 
        />
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Forgot Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Please enter below details to reset your account password.
          </Text>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
          >
            <FormControl>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  placeholder="Enter email"
                  fontWeight="500"
                  size="lg"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  // onChange={(e) => setUser({ ...user, email: e.target.value })}
                /> 
              </InputGroup>
              {formik.touched.email && formik.errors.email ? (
                <Flex color={'red'} fontSize="sm" pl="5px" pt="4px">{formik.errors.email}</Flex>
              ) : null}
              
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                mt="24px"
                rightIcon={<ArrowForwardIcon />}
                type="submit"
              >
                Reset Password
              </Button>
            </FormControl>
          </Flex>
        </form>
      </Flex>
    </DefaultAuth>
  );
}

export default SetNewPassword;
