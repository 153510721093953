import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  Image,
} from "@chakra-ui/react";
import React from "react";

import DefaultAuth from "layouts/auth/Default";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import illustration from "assets/img/auth/auth.png";
import subLogoPNG from "assets/img/logo/alexion.svg";
import logoPNG from "assets/img/logo/Voydeya.svg";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { useFormik } from "formik";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { handleRequest } from "services/axiosConfig";

function SetPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("link.100", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { setGlobalLoading } = useGlobalLoading();

  const [showPassword, setShowPassword] = React.useState(false);
  const handlePasswordClick = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleConfirmPasswordClick = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const toast = useToast();
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: values => {
      const errors: any = {};

      const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
      if (!passwordRegex.test(values.password)) {
        errors.password = "Password must contain at least 6 characters, one capital letter, and one special character";
      }
      if (!passwordRegex.test(values.confirmPassword)) {
        errors.confirmPassword = "Password must contain at least 6 characters, one capital letter, and one special character";
      }

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password must match!";
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        setGlobalLoading(true);
        await handleRequest(
          "/user-authentication/set-password",
          "post",
          {
            "Content-Type": "application/json",
            Authorization: resetPasswordToken,
          },
          false,
          toast,
          values
        );

        navigate("/auth/password-confirmation");
      } finally {
        setGlobalLoading(false);
      }
    },
  });

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
      logo={logoPNG}
      subLogo={subLogoPNG}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        h="100vh"
        alignItems="start"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        mx="auto"
        flexDirection="column"
      >
        <Image
          w="134px"
          h="36px"
          src={subLogoPNG}
          alt="subLogoPNG"
          position="absolute"
          top="20px"
          right="40px" 
        />
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Set Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Please enter below details to set your account password.
          </Text>
        </Box>

        <form onSubmit={formik.handleSubmit}>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
          >
            <FormControl>
              <InputGroup size="md" display="flow">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Password"
                  mb="6px"
                  size="lg"
                  type={showPassword ? "text" : "password"}
                  variant="auth"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handlePasswordClick}
                  />
                </InputRightElement>
                {formik.touched.password && formik.errors.password ? (
                  <Flex color={'red'} fontSize="sm" pl="5px">{formik.errors.password}</Flex>
                ) : null}
              </InputGroup>

              <InputGroup size="md" display="flow" marginTop="20px">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Confirm Password"
                  mb="6px"
                  size="lg"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="auth"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={
                      showConfirmPassword
                        ? RiEyeCloseLine
                        : MdOutlineRemoveRedEye
                    }
                    onClick={handleConfirmPasswordClick}
                  />
                </InputRightElement>
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <Flex color={'red'} fontSize="sm" pl="5px" pb="20px">{formik.errors.confirmPassword}</Flex>
                ) : null}
              </InputGroup>

              <Text
                color={textColorSecondary}
                fontSize="sm"
                w="324px"
                fontWeight="500"
                mb="48px"
              >
                Password must have minimum of 6 characters with at least one
                capital letter & one special character.
              </Text>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                rightIcon={<ArrowForwardIcon />}
                type="submit"
              >
                Set Password
              </Button>
            </FormControl>
          </Flex>
        </form>
      </Flex>
    </DefaultAuth>
  );
}

export default SetPassword;
