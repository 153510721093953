import React from "react";
import {
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";

import DefaultAuth from "layouts/auth/Default";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import illustration from "assets/img/auth/auth.png";
import subLogoPNG from "assets/img/logo/alexion.svg";
import logoPNG from "assets/img/logo/Voydeya.svg";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import background from 'assets/img/auth/confirmation.bg.png';
import { NavLink } from "react-router-dom";

function PasswordConfirmation() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("link.100", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
      logo={logoPNG}
      subLogo={subLogoPNG}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        h="100vh"
        alignItems="start"
        justifyContent="center"
        px={{ base: "25px", md: "0px" }}
        mx="auto"
        flexDirection="column"
      >
        <Image
          w="134px"
          h="36px"
          src={subLogoPNG}
          alt="subLogoPNG"
          position="absolute"
          top="20px"
          right="40px"
        />
        <Image
            w="280px"
            h="280px"
            src={background}
            alt="background"
            alignSelf="center">
        </Image>
        <Text
            color={textColorSecondary}
            fontSize="sm"
            w="324px"
            fontWeight="500"
            mb="24px"
            >
            Congratulations! You have successfully reset your account password.
        </Text>
        <NavLink to="/auth/sign-in">
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            rightIcon={<ArrowForwardIcon />}
            >
            Login Now
          </Button>
        </NavLink>
      </Flex>
    </DefaultAuth>
  );
}

export default PasswordConfirmation;
