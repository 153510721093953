// Chakra imports
import { Flex, Img, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import  Voydeya_TM from '../../../assets/img/logo/Voydeya.svg';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			{/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
			<Img src={Voydeya_TM} alt='Voydeya_TM' w='194px' h='auto' />
			<HSeparator m='20px' h='0' />
		</Flex>
	);
}

export default SidebarBrand;
