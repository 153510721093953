import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import { Field, Formik } from "formik";
import { useRef } from "react";
import { MdPersonAddAlt1 } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { handleRequest } from "services/axiosConfig";

export default function TableHeader(props: {
  headingTitle: string;
  fetchUserData: () => void;
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const headerContentColor = useColorModeValue("brand.450", "white");
  const buttonBgColor = useColorModeValue("white", "brand.450");
  const buttonColor = useColorModeValue("brand.450", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const { setGlobalLoading } = useGlobalLoading();
  const toast = useToast();

  return (
    <Flex
      mb="8px"
      justifyContent="space-between"
      align="center"
      w="100%"
      px="0px"
      py="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Text
        color={textColor}
        fontSize="20px"
        mb="4px"
        fontWeight="600"
        lineHeight="100%"
      >
        {props.headingTitle}
      </Text>
      {/* <Menu /> */}
      <Button
        color={buttonColor}
        borderColor={buttonColor}
        borderWidth="0.5px"
        bg={buttonBgColor}
        borderRadius="10px"
        onClick={onOpen}
        padding={"6px 16px"}
        height="36px"
        rightIcon={<AddIcon />}
      >
        Add User
      </Button>

      <Modal
        isOpen={isOpen}
        size="2xl"
        onClose={onClose}
        initialFocusRef={initialRef}
      >
        <ModalOverlay />
        <Formik
          initialValues={{
            firstNameToRegister: "",
            lastNameToRegister: "",
            emailToRegister: "",
          }}
          onSubmit={async (values) => {
            try {
              setGlobalLoading(true);
              const responseData = await handleRequest(
                "/user-authentication/register-user",
                "post",
                {
                  "Content-Type": "application/json",
                },
                true,
                toast({
                  title: `User added successfully!`,
                  status: "success",
                  duration: 3000,
                  position: "top-right",
                  isClosable: true,
                }),
                values
              );
            } finally {
              setGlobalLoading(false);
              onClose();
              props.fetchUserData();
            }
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <ModalContent>
              <ModalHeader
                display="flex"
                alignItems="center"
                color={headerContentColor}
              >
                <Icon as={MdPersonAddAlt1} boxSize={6} mr={2} />
                Add User
              </ModalHeader>
              <ModalCloseButton />
              <form onSubmit={handleSubmit}>
                <ModalBody pb={6}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <GridItem>
                      <FormControl
                        isInvalid={
                          !!errors.firstNameToRegister &&
                          touched.firstNameToRegister
                        }
                      >
                        <FormLabel>First Name</FormLabel>
                        <Field
                          as={Input}
                          id="firstNameToRegister"
                          name="firstNameToRegister"
                          validate={(value: any) => {
                            let error;

                            if (value.length < 1) {
                              error =
                                "First name must contain at least 1 characters";
                            }

                            return error;
                          }}
                        />
                        <FormErrorMessage>
                          {errors.firstNameToRegister}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>Last Name</FormLabel>
                        <Field
                          as={Input}
                          id="lastNameToRegister"
                          name="lastNameToRegister"
                          type="text"
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>

                  <FormControl mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Field
                      as={Input}
                      id="emailToRegister"
                      name="emailToRegister"
                      type="email"
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="brand"
                    onClick={onClose}
                    variant={"outline"}
                    mr={3}
                  >
                    Discard
                  </Button>
                  <Button type="submit" colorScheme="brand">
                    Save Details
                  </Button>
                </ModalFooter>
              </form>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </Flex>
  );
}
