import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import Unauthorized from "../components/Unauthorized";
import { useAuth } from "../provider/AuthProvider";

const Authorization = ({ permissions }) => {
    const { user } = useAuth();
    const location = useLocation();
    // console.log("user is ", user);

    if (user.userEmail !== "") {
        // console.log("this is happening", user)
        const userpermission = user.userPermissions;
        const isAllowed = permissions.some((allowed) => userpermission.includes(allowed));
        return isAllowed ? <Outlet /> : <Unauthorized />;
    }
    else {
        return <Navigate to="/" state={{ path: location.pathname }} replace />;
    }
};
export default Authorization;
