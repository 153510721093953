import { ChakraProvider } from "@chakra-ui/react";
import { GlobalLoadingProvider } from "contexts/GlobalLoadingContext";
import { AuthProvider } from "provider/AuthProvider";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HashRouter } from 'react-router-dom';
import RoutePath from "routes/RoutePath";
import "./assets/css/App.css";
import theme from "./theme/theme";
import LogoutTimer from "LogoutTimer";

ReactDOM.render(
  <ChakraProvider
    theme={theme}
    // toastOptions={{ defaultOptions: { position: "top-right" } }}
  >
    <React.StrictMode>
      <HashRouter>
        <GlobalLoadingProvider>
          <AuthProvider>
          <LogoutTimer />
            <RoutePath />
          </AuthProvider>
        </GlobalLoadingProvider>
      </HashRouter>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
