// Chakra imports
import { Box, Flex, Image } from "@chakra-ui/react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import PropTypes from "prop-types";
// Custom components
// Assets

function AuthIllustration(props: {
  children: JSX.Element | string;
  illustrationBackground: string;
  logo: string;
  subLogo: string;
}) {
  const { children, illustrationBackground, logo, subLogo } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        // maxW={{ md: "66%", lg: "1313px" }}
        // mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        // px={{ lg: "30px", xl: "0px" }}
        // ps={{ xl: "70px" }}
        justifyContent="space-between"
        direction="row"
        // alignItems="flex-end"
      >
        {/* <NavLink
          to="/admin"
          style={() => ({
            width: "fit-content",
            marginTop: "40px",
          })}
        >
          <Flex
            align="center"
            ps={{ base: "25px", lg: "0px" }}
            pt={{ lg: "0px", xl: "0px" }}
            w="fit-content"
          >
            <Icon
              as={FaChevronLeft}
              me="12px"
              h="13px"
              w="8px"
              color="secondaryGray.600"
            />
            <Text ms="0px" fontSize="sm" color="secondaryGray.600">
              Back to Simmmple
            </Text>
          </Flex>
        </NavLink> */}
        <Box
          display={{ base: "none", md: "block" }}
          h="100%"
          minH="100vh"
          w={{ lg: "45vw", "2xl": "44vw" }}
          position="relative"
        >
          <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
          >
            <Image
              w="347px"
              h="134px"
              src={logo}
              alt="logo"
              alignSelf="center"
            />
            {/* <Image
              w="134px" // Set the desired width for subLogo
              h="36px" // Set the desired height for subLogo
              src={subLogo}
              alt="subLogo"
              position="absolute"
              top="20px" // Adjust the top position as needed
              right="20px" // Adjust the right position as needed
            /> */}
          </Flex>
        </Box>
        {children}
        {/* <Footer /> */}
      </Flex>
      {/* <FixedPlugin /> */}
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
