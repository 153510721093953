import {
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import comingSoon from "assets/img/user-usage/user-usage.png";
import Card from "components/card/Card";
import { useGlobalLoading } from "contexts/GlobalLoadingContext";
import TableHeader from "./components/tableHeader";

export default function UserUsage() {
  const [date, setDate] = React.useState(new Date());
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#cdd9e8", "white");
  const rowSeperatorColor = useColorModeValue(
    "rgba(6, 64, 139, 0.2)",
    "rgba(100,100,100,0.2)"
  );
  const { setGlobalLoading } = useGlobalLoading();
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [selectedDates, setSelectedDates] = React.useState<Date[]>([
    new Date(),
    new Date(),
  ]);

  return (
    <Box h={"90vh"} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <TableHeader headingTitle="Users Adherence" />
      <Card
        flexDirection="column"
        w="100%"
        h="100%"
        px="0px"
        py="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        borderRadius={"10px"}
        borderWidth="1px"
        borderColor={rowSeperatorColor}
      >
        <Box
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          px="0px"
          py="0px"
          display={"flex"}
          verticalAlign={"middle"}
          h="100%"
          flexDir={"column"}
        >
          <HStack margin={"20px 0"}>
            <VStack w="250px" alignItems="center">
              <Flex 
                bgImage={comingSoon} 
                bgSize='cover'
                h="250px"
                w="360px"
                mb="20px"
			          >
              </Flex>
              <Text
                fontWeight="600"
                fontSize="18px"
                color="#06408B"
                >
                Coming Soon!
              </Text>
            </VStack>
          </HStack>
        </Box>
      </Card>
    </Box>
  );
}
