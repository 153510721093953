import { Icon } from "@chakra-ui/react";
import { MdAccountBalance, MdLock } from "react-icons/md";

// Admin Imports
import DataTables from "views/admin/dataTables";

// Auth Imports
import UserAdherence from "components/user-adherence/userAdherence";
import UserManagement from "components/users-management/usersManagement";
import SignInCentered from "views/auth/signIn";
import Dashboard from "pages/dashboard/Dashboard";
import ContentManagement from "pages/content-management/ContentManagement";
import UserUsage from "pages/user-usage/UserUsage";
import SetPassword from "views/auth/setPassword";
import PasswordConfirmation from "views/auth/setPassword/components/passwordConfirmation";
import SetNewPassword from "views/auth/setNewPassword";
import { FaFolderOpen, FaUserClock, FaUserCog, FaUserTimes } from "react-icons/fa";

const routes = [
  //   {
  //     name: "Main Dashboard",
  //     layout: "/admin",
  //     path: "/default",
  //     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //     component: MainDashboard,
  //   },
  //   {
  //     name: "NFT Marketplace",
  //     layout: "/admin",
  //     path: "/nft-marketplace",
  //     icon: (
  //       <Icon
  //         as={MdOutlineShoppingCart}
  //         width="20px"
  //         height="20px"
  //         color="inherit"
  //       />
  //     ),
  //     component: NFTMarketplace,
  //     secondary: true,
  //   },
  //   {
  //     name: "Data Tables",
  //     layout: "/admin",
  //     icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //     path: "/data-tables",
  //     component: DataTables,
  //   },
  // {
  //   name: "Dashboard",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/default",
  //   showInSideBar: true,
  //   component: Dashboard,
  // },
  {
    name: "Users Management",
    layout: "/admin",
    icon: <Icon as={FaUserCog} width="20px" height="20px" color="inherit" />,
    path: "/default",
    showInSideBar: true,
    component: UserManagement,
  },
  {
    name: "Content Management",
    layout: "/admin",
    icon: <Icon as={FaFolderOpen} width="20px" height="20px" color="inherit" />,
    path: "/content-management",
    showInSideBar: true,
    component: ContentManagement,
  },
  {
    name: "Users Adherence",
    layout: "/admin",
    icon: <Icon as={MdAccountBalance} width="20px" height="20px" color="inherit" />,
    path: "/user-adherence",
    showInSideBar: true,
    component: UserAdherence,
  },
  {
    name: "User Usage",
    layout: "/admin",
    icon: <Icon as={FaUserClock} width="20px" height="20px" color="inherit" />,
    path: "/user-usage",
    showInSideBar: true,
    component: UserUsage,
  },
  //   {
  //     name: "Profile",
  //     layout: "/admin",
  //     path: "/profile",
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //     component: Profile,
  //   },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    showInSideBar: false,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    showInSideBar: false,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SetNewPassword,
  },
  {
    name: "Password Confirmation",
    layout: "/auth",
    path: "/password-confirmation",
    showInSideBar: false,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PasswordConfirmation,
  },
  {
    name: "Set Password",
    layout: "/auth",
    path: "/set-password/:resetPasswordToken",
    showInSideBar: false,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SetPassword,
  },
  //   {
  //     name: "RTL Admin",
  //     layout: "/rtl",
  //     path: "/rtl-default",
  //     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //     component: RTL,
  //   },
];

export default routes;
